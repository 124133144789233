import React from 'react';
import {
  Added,
  Fixed,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="September 2019"
      subnav="release-notes">
      <div id="September2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          A burst of energy happened while cooler weather approached. It
          included an expansion to the Figma library, contributions from some of
          our brightest, and new React Native form components.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Mobile guidelines for{' '}
                <Link href="/guidelines/content-selection/design">
                  content selection
                </Link>{' '}
                when tapping.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.33.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.33.1"
            />
            <div className={styles.changesList} />
            <Added>
              <Link href="/components/icon/design">Icons</Link> for:
              <List>
                <li>
                  <code>expandContent</code> (Thanks,{' '}
                  <Link href="https://github.com/cz">Craig</Link>
                  !)
                </li>
                <li>
                  <code>home</code>
                </li>
                <li>
                  <code>record</code>
                </li>
                <li>
                  <code>leagueExchange</code>, and
                </li>
                <li>
                  <code>sync</code>.
                </li>
              </List>
            </Added>
            <Fixed>
              Hover state of{' '}
              <Link href="/components/avatars/design">avatars</Link> when
              onClick is provided.
            </Fixed>
            <Fixed>
              The onAfterOpen firing before the modal open animation completed.
              (Thanks, <Link href="https://github.com/benrhere">Ben</Link>
              !)
            </Fixed>
            <Fixed>
              The data table not re-rendering when new data is supplied to it.
              (Thanks, <Link href="https://github.com/swindiggie">Nelson</Link>
              !)
            </Fixed>
            <Fixed>
              {' '}
              How the <code>qaId</code> property from actions passed into the{' '}
              <Link href="/components/action-bar/code">action bar</Link>{' '}
              component. (Thanks,{' '}
              <Link href="https://github.com/ashlyn">Ashlyn</Link>
              !)
            </Fixed>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Android"
              version="5.5.0"
              versionUrl="https://github.com/hudl/android-framework/releases/tag/v5.5.0"
            />

            <div className={styles.changesList}>
              <Added>Icons, yay!</Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="iOS"
              version="6.4.0"
              versionUrl="https://github.com/hudl/ios-uniform-ui/releases/tag/v6.4.0"
            />

            <div className={styles.changesList}>
              <Added>Those same icons.</Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="7.5.0"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v7.5.0"
            />
            <div className={styles.changesList}>
              <Added>Those beautiful icons.</Added>
              <Added>
                {' '}
                Both the{' '}
                <Link href="/components/forms/input/design">
                  input
                </Link> and{' '}
                <Link href="/components/forms/text-area/design">text area</Link>{' '}
                to create forms.
              </Added>
              <Added>
                The{' '}
                <Link href="/components/notifications/notice/design">
                  notice
                </Link>{' '}
                for mobile.
              </Added>
              <Added>
                A <code>space</code> prop for every component needing control of
                extra space.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />

            <div className={styles.changesList}>
              <Added>
                A library to use components in low-fidelity mockups.
              </Added>
              <Added>
                Those icons that made an appearance everywhere else.
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
